@import '~react-md/dist/react-md';
// @import 'variables';
// @import '~@react-md/layout/dist/mixins';
// @import '~@react-md/states/dist/mixins';
// @import '~@react-md/theme/dist/mixins';
// @import '~@react-md/typography/dist/mixins';
// @import '~@react-md/utils/dist/mixins';
@import '~@react-md/list/dist/mixins';
// @import '~@react-md/elevation/dist/mixins';

@include react-md-utils;

//  Don't scroll on phone
@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }

  body {
    overflow: hidden;
    position: relative;
  }
}

body {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  touch-action: none;

  font-family: 'Roboto', sans-serif;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent; // for iframe
}

.column_container {
  flex: 1 1 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: row;
}

.flex-column {
  flex-direction: column !important;
}

.container-1 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container-2 {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.container-3 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/**
 * Use this class to hide scrollbar
 */
.scrollable {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.hide_scroll_bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.cursor-hand {
  cursor: pointer;
}

.react-tooltip {
  text-transform: none !important;
  opacity: 1 !important;
  background-color: #1e2836 !important;
  box-shadow: 0px 2px 10px rgba(20, 31, 44, 0.8) !important;
}

.react-tooltip-small {
  opacity: 1 !important;
  background-color: #1e2836 !important;
  margin-top: 0 !important;
  text-transform: initial !important;
}

.react-tooltip-small-position {
  opacity: 1 !important;
  background-color: #061420 !important;
  margin-top: 0 !important;
  text-transform: initial !important;
  &::before {
    border-top: 4px solid #061420 !important;
  }
  &::after {
    border-top-color: #061420 !important;
  }
}

.react-tooltip-nopadding {
  text-transform: none !important;
  opacity: 1 !important;
  background-color: #1e2836 !important;
  box-shadow: 0px 2px 10px rgba(20, 31, 44, 0.8) !important;
  padding: 0 !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.bot-widget-bubble {
  display: none !important;
}
